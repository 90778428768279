/* Z-Index Range Use Recommendations

Z-Index < 0:
    - Background and or hidden elements

Z-Index 0 - 4,999:
    - Main Content

Z-Index 5,000 - 1,999,999:
    - Expanding content such as pushdowns or accordions.
    - The entire expandable unit should be set within this range

Z-Index 5,000,000 - 5,999,999:
    - Expanding site navigation elements
    - Sticky elements
    - Drop down navigation
    - site warnings
    - Basically, anything that is allowed to cover content.
    - Only the expanding portion of elements should be included on this level.
*/

$animation-container-z-index: 0;
$content-z-index: 1;
/* Page header needs some z-index to stay above the sidebar */
$page-header-z-index: 20;
/* Desktop should always be less than page-header so it will flow behind. */
$sidebar-z-index-desktop: 10;
/* Mobile should be the same as page-header so they flow the same. */
$sidebar-z-index-mobile: 20;
