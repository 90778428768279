@use "@themuse/design-system/lib/styles/colors";
@use "@themuse/design-system/lib/styles/mixins";
@use "/src/scss/variables/z-indexes";

.container {
    z-index: z-indexes.$content-z-index;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 66px;
    padding: 16px 8px;
    background-color: colors.$primary-80;

    @include mixins.viewport("sm") {
        background-color: transparent;
        padding: 0;
        top: 12px;
        left: 105px;
        width: 328px;
        flex-direction: column;
    }

    @include mixins.viewport("md") {
        top: 15px;
        left: 133px;
    }

    @include mixins.viewport("lg") {
        left: 190px;
    }
}

.containerOpened {
    composes: container;
    background-color: colors.$neutral-white;
    position: fixed;
    top: 0;
    padding: 11px 8px;

    @include mixins.viewport("sm") {
        background-color: transparent;
        position: absolute;
        top: 12px;
        padding: 0;

        @include mixins.viewport("md") {
            top: 15px;
        }
    }
}

.inputContainer {
    width: calc(100% - 40px);
    margin: 0 20px;
    height: 40px;
    border: 2px solid transparent;

    &:focus-within {
        border: 2px solid colors.$secondary-100;
    }

    @include mixins.viewport("sm") {
        margin: 0;
    }

    input {
        font-size: 14px;
        color: colors.$secondary-100;
        width: 100%;

        &::placeholder {
            color: colors.$secondary-100;
            font-size: 14px;
        }
    }
}

.inputContainerOpened {
    margin: 0;

    @include mixins.viewport("sm") {
        border: 2px solid colors.$secondary-100;
        border-radius: 4px;
        width: calc(100% - 40px);
        height: 40px;
    }

    .input {
        width: 100%;
        font-size: 14px;

        &::placeholder {
            font-size: 14px;
        }
    }

    .inputClearButton {
        top: 0;

        @include mixins.viewport("sm") {
            top: -3px;
        }
    }
}

.menuOpened {
    @include mixins.viewport("sm") {
        width: calc(100% - 40px);
        display: block;
        position: relative;
        border-radius: 4px;
        top: 0;
        height: 240px;
    }
}

.closeButton {
    padding: 10px 15px;
}

.inputClearButton {
    top: -3px;

    @include mixins.viewport("sm") {
        top: -4px;
    }
}

.menuList {
    padding: 0;
}

.listItem {
    position: relative;
    padding: 0;

    svg {
        position: absolute;
        left: 16px;
        top: 14px;
    }

    a {
        margin: 0 0 0 32px;
        line-height: 19px;
        display: block;
        padding: 10px;
    }

    &:first-child {
        .listItemText {
            quotes: '\201c' '\201d';

            &:before {
                content: open-quote;
            }

            &:after  {
                content: close-quote;
            }
        }
    }

    .listItemText {
        quotes: '\201c' '\201d';
        max-width: calc(100% - 68px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
    }
}

.postLabel {
    margin: 0 0 0 8px;
    font-weight: 400;
    color: colors.$tm-dim-gray;
    vertical-align: top;
}
